

















































































import Vue from 'vue';
const Ripple = require('vue-ripple-directive');
import { mapActions, mapState } from 'vuex';
import { Monitor } from '../store/monitors/types';
import axios from 'axios';

interface EditMonitor extends Monitor {
  dirty: boolean;
}
export default Vue.extend({
  components: {},
  directives: {
    Ripple,
  },
  data() {
    let me = this;
    const columns = [
      {
        key: 'selected',
        label: '選擇',
      },
      {
        key: '_id',
        label: '代碼',
      },
      {
        key: 'desc',
        label: '名稱',
        sortable: true,
      },
      {
        key: 'measuring',
        label: '測量中',
      },
    ];

    let editMonitors = Array<EditMonitor>();
    let selected: EditMonitor | undefined;
    return {
      display: false,
      columns,
      editMonitors,
      selected,
    };
  },
  computed: {
    ...mapState('monitors', ['monitors', 'activeID']),
    ...mapState('monitorTypes', ['monitorTypes']),
  },
  async mounted() {
    await this.fetchMonitors();
    await this.fetchMonitorTypes();
    await this.getActiveID();
    this.copyMonitor();
  },
  methods: {
    ...mapActions('monitors', ['fetchMonitors', 'getActiveID', 'setActiveID']),
    ...mapActions('monitorTypes', ['fetchMonitorTypes']),
    save() {
      const all = Array<any>();
      for (const m of this.editMonitors) {
        if (m.dirty) {
          all.push(axios.put(`/Monitor/${m._id}`, m));
        }
      }

      Promise.all(all).then(() => {
        this.fetchMonitors();
        this.$bvModal.msgBoxOk('成功');
      });
    },
    rollback() {
      this.fetchMonitors();
    },
    copyMonitor() {
      this.editMonitors.splice(0, this.editMonitors.length);
      for (let m of this.monitors) {
        this.editMonitors.push(Object.assign({ dirty: false }, m));
      }
    },
    async deleteMonitor() {
      if (this.selected === undefined) return;

      if (this.editMonitors.length === 1) {
        this.$bvModal.msgBoxOk('應至少有一個測點');
        return;
      }

      const confirm = await this.$bvModal.msgBoxConfirm(
        `確定要刪除${this.selected._id}?`,
        { okTitle: '確認', cancelTitle: '取消' },
      );

      if (!confirm) return;

      const _id = this.selected._id;
      const res = await axios.delete(`/Monitor/${_id}`);
      if (res.status == 200) this.$bvModal.msgBoxOk('成功');
      await this.fetchMonitors();
      this.copyMonitor();
    },
    addMonitor() {
      this.editMonitors.push({
        _id: `me${this.editMonitors.length}`,
        desc: '',
        dirty: true,
      });
    },
    markDirty(item: any) {
      item.dirty = true;
    },
    onInstSelected(items: Array<EditMonitor>) {
      this.selected = items[0];
    },
    select() {
      this.setActiveID(this.selected?._id);
    },
  },
});
